<mat-toolbar color="primary">
  <button mat-icon-button aria-label="Side navigation panel toggle button"
          (click)="sidenavToggle.emit()">
    <fa-icon [icon]="['far', 'bars']" size="lg"></fa-icon>
  </button>
  <button mat-flat-button color="primary" routerLink="/">{{appTitle | translate: '{tenant: "VAN IN"}'}}</button>

  <span class="flex-spacer"></span>
  <ng-content additional-elements></ng-content>

  <ng-container *ngIf="errorOccurred">
    <div class="d-flex align-items-center pointer" [matMenuTriggerFor]="menu">
      <div class="d-flex align-items-center pointer">
        <button id="context-menu-when-error" mat-flat-button color="primary">
          Login
          <fa-icon [icon]="['far', 'user']" class="ml-2" size="lg"></fa-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!errorOccurred">
    <ng-container *ngIf="(authService.isAuthenticated$ | async) === false">
      <ng-container *ngIf="languageService.getCurrent() | async as language">
        <button id="login-btn" mat-flat-button color="primary" (click)="onLoginClick(language.code)">
          Login
          <fa-icon [icon]="['far', 'user']" class="ml-2" size="lg"></fa-icon>
        </button>
      </ng-container>
    </ng-container>
    <section class="ml-1">
      <lib-avatar *ngIf="(authService.isAuthenticated$ | async) === true && avatarProps"
                  [props]="avatarProps"
                  [withAccountSettings]="!isCustomerServiceContext"
                  (onLogoutClick)="onLogoutClick()"
                  (onAccountSettingsClick)="onAccountSettingsClick()">
      </lib-avatar>
    </section>
  </ng-container>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <app-menu-item id="logout-btn" label="Logout" icon="right-from-bracket" (click)="onLogoutClick()"></app-menu-item>
</mat-menu>
