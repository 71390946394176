export interface FeatureResponse {
  features: { [key: string]: boolean };
}

export class Feature {

  constructor(
    public features: Map<string, boolean>
  ) {
  }
}

export enum FeatureType {
  osdiImport = 'osdiImport',
  manageWisaOrganisations = 'manageWisaOrganisations',
  extendedQueryActivationCodes = 'extendedQueryActivationCodes',
  extendedQueryAssignees = 'extendedQueryAssignees',
  extendedQueryActivations = 'extendedQueryActivations',
  extendedQueryPhysicalCodes = 'extendedQueryPhysicalCodes',
  username = 'username',
  supportSimplifiedAccount = 'supportSimplifiedAccount',
  thirdPersonAccountCreationSE = 'thirdPersonAccountCreationSE',
  thirdPersonAccountCreationPE = 'thirdPersonAccountCreationPE',
  licenseRevokePeriod = 'licenseRevokePeriod'
}

