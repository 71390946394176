import {Component} from '@angular/core';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {MainSidevanPanelService} from 'src/app/shared/service/main-sidevan-panel.service';
import {Router} from '@angular/router';
import {OnAfterCreate} from 'src/app/shared/util/on-after-create';
import {LibBaseComponent, MediaMatcherService} from '@sesame/sesame-fe-library';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent extends LibBaseComponent implements OnAfterCreate {

  readonly menuItems = AppVariables.navigationItems[environment.entryPoint]
    .filter(item => item.id !== 'home');

  constructor(
    private readonly sidenavPanelService: MainSidevanPanelService,
    private readonly router: Router,
    private readonly mediaMatcher: MediaMatcherService,
  ) {
    super();
  }

  onAfterCreate(): void {
    this.sidenavPanelService.close();
  }

  navigate(path: string): void {
    this.router.navigate([path]).then(() => {
      if (!this.mediaMatcher.matchMedia().matches) {
        this.sidenavPanelService.open();
      }
    });
  }
}
