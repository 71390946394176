import {Component} from '@angular/core';
import {LibBaseComponent, LibOrganisationContextService, SnackBarService} from '@sesame/sesame-fe-library';
import {AuthService} from '@auth0/auth0-angular';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "src/environments/environment";
import {combineLatestWith, EMPTY} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends LibBaseComponent {


  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    readonly organisationContext: LibOrganisationContextService,
    readonly snackbar: SnackBarService,
    readonly translate: TranslateService
  ) {
    super();
    authService.isAuthenticated$
      .pipe(
        combineLatestWith(organisationContext.getCurrent()),
        this.takeUntilDestroy()
      )
      .subscribe(([isAuthenticated, currentOrganisation]) => {
        if (isAuthenticated) {
          if (!environment.isCustomerServiceContext && currentOrganisation == null) {
            snackbar.showWarning(translate.instant('error.not-found.administrationRole'), SnackBarService.FOREVER_DURATION);
          } else {
            this.redirect();
          }
        } else {
          this.tryToGetAccessTokenSilently();
        }
      });
  }

  redirect(): void {
    if (this.router.url === '/') {
      if (environment.isCustomerServiceContext) {
        this.router.navigate(['app']);
      } else {
        this.router.navigate(['licenses']);
      }
    }
  }

  private tryToGetAccessTokenSilently() {
    (this.authService as any).auth0Client.options.useRefreshTokens = false;
    this.authService.getAccessTokenSilently()
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        (this.authService as any).auth0Client.options.useRefreshTokens = true;
      })
  }
}
