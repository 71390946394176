import {Injectable} from '@angular/core';
import {LmsHttpClient, OsmHttpClientService} from '@sesame/sesame-fe-library';
import {combineLatest, Observable} from 'rxjs';
import {Feature, FeatureResponse} from 'src/app/shared/models/feature';
import {map} from 'rxjs/operators';
import {QueryBuilder} from 'src/app/shared/util/query-builder';

@Injectable({
  providedIn: 'root'
})
export class FeatureQuery {

  constructor(
    private readonly lmsHttpClient: LmsHttpClient,
    private readonly osmHttpClient: OsmHttpClientService
  ) {
  }

  getFeatures(): Observable<Feature> {
    const featuresFromLms = this.lmsHttpClient.get<FeatureResponse>(QueryBuilder.forPath('features').build())
      .pipe(
        map((response: FeatureResponse) => this.toFeature(response))
      );
    const featuresFromOsm = this.osmHttpClient.get<FeatureResponse>(QueryBuilder.forPath('features').build())
      .pipe(
        map((response: FeatureResponse) => this.toFeature(response))
      );

    return combineLatest([featuresFromLms, featuresFromOsm])
      .pipe(map(([lmsFeatures, osmFeatures]) =>
        new Feature(new Map([...lmsFeatures.features.entries(), ...osmFeatures.features.entries()])))
      );
  }

  private toFeature(response: FeatureResponse): Feature {
    let adjustedResponse = this.adjustResponse(response);

    return new Feature(new Map(Object
      .keys(adjustedResponse.features)
      .map(key => [key, adjustedResponse.features[key]])));
  }

  private adjustResponse(response: FeatureResponse): FeatureResponse {
    var str = JSON.stringify(response);
    str = str.replace(/3rdPersonAccountCreationSE/g, 'thirdPersonAccountCreationSE');
    str = str.replace(/3rdPersonAccountCreationPE/g, 'thirdPersonAccountCreationPE');

    return JSON.parse(str);
  }
}
