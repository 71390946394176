import {Injectable} from '@angular/core';
import {FeatureQuery} from 'src/app/shared/service/feature.query';
import {Feature, FeatureType} from 'src/app/shared/models/feature';
import {Observable} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  enabledFeatures: FeatureType[] = [];
  features$: Observable<Map<string, boolean>>;

  constructor(
    private readonly featureQuery: FeatureQuery,
  ) {
    this.features$ = this.featureQuery.getFeatures().pipe(
      map(response => response.features),
      tap(features => {
        this.enabledFeatures = Array.from(features.keys()).filter(feature => features.get(feature)) as FeatureType[];
      }),
      shareReplay(1)
    );
  }

  isEnabled(feature: FeatureType): boolean {
    return this.enabledFeatures.includes(feature);
  }

  isEnabled$(feature: FeatureType): Observable<boolean> {
    return this.features$.pipe(
      map(featureMap => featureMap.get(feature)),
    );
  }

  getAllFeatures$(): Observable<{ [key: string]: boolean }> {
    return this.featureQuery.getFeatures().pipe(
      map(response => {
        const featureFlags: { [key: string]: boolean } = {};
        response.features.forEach((value, key) => {
          featureFlags[FeatureType[key]] = value;
        });
        return featureFlags;
      }),
      shareReplay(1)
    );
  }

  getFeatures$(): Observable<Feature> {
    return this.featureQuery.getFeatures();
  }

  filterEnabledFeatures(feature: Feature): FeatureType[] {
    let enabledFeatures: FeatureType[] = [];
    feature.features.forEach((value, key) => {
      if (value) {
        enabledFeatures.push(FeatureType[key]);
      }
    });
    return enabledFeatures;
  }
}
