import {BrowserModule, Title} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {ProductsModule} from './features/products/products.module';
import {AuthorizationModule} from './core/authorization/authorization.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginPageComponent} from './core/pages/login-page/login-page.component';
import {SecuredContentPageComponent} from 'src/app/core/pages/secured-content-page/secured-content-page.component';
import {MainToolbarComponent} from './core/components/main-toolbar/main-toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSliderModule,} from '@angular/material/slider';
import {MatButtonModule} from '@angular/material/button';
import {MainSidevanPanelComponent} from './core/components/main-sidevan-panel/main-sidevan-panel.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {NotFoundPageComponent} from './core/pages/not-found-page/not-found-page.component';
import {MenuItemComponent} from './core/components/menu-item/menu-item.component';
import {SidenavMenuItemComponent} from './core/components/sidenav-menu-item/sidenav-menu-item.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DateAdapter, MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from 'src/app/shared/util/custom-date-adapter';
import {HomePageComponent} from './core/pages/home-page/home-page.component';
import {
  AvatarModule,
  CiamResultComponent,
  CoreModule,
  DialogModule,
  EnvironmentService,
  EnvironmentServiceBuilder,
  LibLanguageService,
  LinkingService,
  PrivilegeService,
  SchoolsModule,
  SesameLibraryModule,
  SnackBarService,
  SpinnerModule
} from '@sesame/sesame-fe-library';
import {MatGridListModule} from '@angular/material/grid-list';
import {ConfirmationDialogComponent} from './core/components/confirmation-dialog/confirmation-dialog.component';
import {DownloadingTaskDialogComponent} from './core/components/downloading-task-dialog/downloading-task-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {EmptyPageComponent} from './core/pages/empty-page/empty-page.component';
import {ErrorHandlerService} from 'src/app/shared/service/error-handler.service';
import {OrganisationSupportContentPageComponent} from './core/pages/organisation-support-content-page/organisation-support-content-page.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from 'src/environments/environment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {OssLanguageService} from 'src/app/shared/service/language/oss-language.service';
import {CsLanguageService} from 'src/app/shared/service/language/cs-language.service';
import {CspBackendQuery} from 'src/app/shared/service/csp-backend.query';
import {MockTranslateLoader} from 'src/app/shared/service/mock-translate-loader';
import {OrganisationDetailModule} from './shared/module/organisation-detail/organisation-detail.module';
import {OverlayContainer} from '@angular/cdk/overlay';
import {IconModule} from 'src/app/shared/module/icon/icon.module';
import {OsmLinkingService} from "src/app/shared/service/osm-linking.service";
import {SmsDataImportModule} from "src/app/features/sms-data-import/sms-data-import.module";
import {AppPageComponent} from "src/app/core/pages/app-page/app-page.component";
import {NgxPiwikProModule, NgxPiwikProRouterModule} from "@piwikpro/ngx-piwik-pro";
import {ResultPageComponent} from "src/app/core/pages/result-page/result-page.component";

const LanguageServiceFactory = (authService: AuthService, cspBackendQuery: CspBackendQuery): LibLanguageService => {
  if (environment.entryPoint === 'OSS') {
    return new OssLanguageService(authService, cspBackendQuery);
  }
  return new CsLanguageService();
};

// AoT requires an exported function for factories
const HttpLoaderFactory = (): TranslateLoader => {
  return new MockTranslateLoader();
};


function environmentFactory(): EnvironmentService {
  return new EnvironmentServiceBuilder()
    .withAppDescription({
      version: environment.app.version,
      production: environment.production,
      correlationIdPrefix: environment.entryPoint
    })
    .withAuth0Config({
      clientId: environment.auth0.config.clientId,
      domain: environment.auth0.config.domain,
      authorizationParams: {
        audience: environment.auth0.config.authorizationParams.audience,
        redirectUri: environment.auth0.config.authorizationParams.redirect_uri
      },
      loginOptions: {}
    })
    .withUpsConfig({url: environment.ups.baseUrl})
    .withOsmConfig({
      url: environment.osm.baseUrl
    })
    .withLmsConfig({
      url: environment.lms.baseUrl
    })
    .withCspBackendConfig({
      url: environment.cspBackend.baseUrl
    })
    .withApdConfig({
      url: environment.apd.baseUrl
    })
    .withLogoFileConfig({
      favIcon: environment.logo.favIcon,
      full: environment.logo.full,
      raw: environment.logo.raw,
      description: 'VAN IN'
    })
    .withOrganisationContextConfig({
      allowedRoles: environment.organisationContext.allowedRoles
    })
    .withSpinnerExcludedPaths({
      paths: [
        'organisations/([0-9a-fA-F-]+)/client',
        'users/generate-username'
      ]
    })
    .withPrivilegeServiceConfig({
      disablePrivileges: environment.disablePrivileges,
      enableOrganisationContext: !environment.isCustomerServiceContext,
      includeAPDService: environment.isCustomerServiceContext,
    })
    .build();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SecuredContentPageComponent,
    MainToolbarComponent,
    MainSidevanPanelComponent,
    NotFoundPageComponent,
    MenuItemComponent,
    SidenavMenuItemComponent,
    HomePageComponent,
    AppPageComponent,
    ConfirmationDialogComponent,
    DownloadingTaskDialogComponent,
    EmptyPageComponent,
    OrganisationSupportContentPageComponent,
    ResultPageComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      }
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthorizationModule,
    ProductsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSliderModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatMenuModule,
    SpinnerModule,
    MatGridListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    OrganisationDetailModule,
    IconModule,
    CoreModule,
    SchoolsModule,
    SesameLibraryModule,
    DialogModule,
    AvatarModule,
    SmsDataImportModule,
    NgxPiwikProRouterModule,
    CiamResultComponent,
    environment.piwikPro ? NgxPiwikProModule.forRoot(environment.piwikPro.containerId, environment.piwikPro.containerUrl, 'piwik-pro') : []
  ],
  providers: [
    SnackBarService,
    Title,
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: LinkingService, useClass: OsmLinkingService},
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: ErrorHandlerService, useClass: ErrorHandlerService},
    {provide: LibLanguageService, useFactory: LanguageServiceFactory, deps: [AuthService, CspBackendQuery]},
    PrivilegeService,
    {provide: EnvironmentService, useFactory: environmentFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    if (environment.entryPoint === 'OSS') {
      overlayContainer.getContainerElement().classList.add('oss-theme');
    }
  }
}

