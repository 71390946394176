import {ChangeDetectorRef, Component, Injector, Input, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {
  LibBaseComponent,
  LibOrganisationContextService,
  MediaMatcherService,
  MyOrganisation
} from '@sesame/sesame-fe-library';
import {OnAfterCreate} from 'src/app/shared/util/on-after-create';
import {NavigationItem} from 'src/app/shared/models/navigation-item';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from "src/environments/environment";
import {Observable, of, switchMap} from "rxjs";
import {OrganisationQuery} from "src/app/shared/service/organisation-query";
import {shareReplay} from "rxjs/operators";

@Component({
  selector: 'app-main-sidevan-panel',
  templateUrl: './main-sidevan-panel.component.html',
  styleUrls: ['./main-sidevan-panel.component.scss']
})
export class MainSidevanPanelComponent extends LibBaseComponent implements OnAfterCreate {
  readonly mobileQuery: MediaQueryList;

  readonly _mobileQueryListener: () => void;
  @Input()
  menuItems: NavigationItem[];

  @ViewChild(MatSidenav)
  readonly sidenav: MatSidenav;

  public readonly defaultToggleStatus = AppVariables.sidenav.default.toggleStatus;
  initialized = false;
  isAuthenticated = false;
  isPlatformUser = true;

  constructor(changeDetectorRef: ChangeDetectorRef,
              mediaMatcher: MediaMatcherService,
              authService: AuthService,
              private readonly injector: Injector,
              private readonly organisationQuery: OrganisationQuery
  ) {
    super();
    authService.isAuthenticated$
      .pipe(this.takeUntilDestroy())
      .subscribe(isAuthenticated$ => this.isAuthenticated = isAuthenticated$);
    this.mobileQuery = mediaMatcher.matchMedia();
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (environment.isCustomerServiceContext) {
      this.getCurrentOrganisation()
        .pipe(
          this.takeUntilDestroy(),
          switchMap(myOrganisation => myOrganisation ? this.organisationQuery.findOne(myOrganisation.organisationId) : of(null))
        )
        .subscribe(organisation => this.isPlatformUser = organisation?.isPlatformUser);
      this.handleLicenseTabVisibility();
    }
  }

  private handleLicenseTabVisibility(): void {
    this.getCurrentOrganisation()
      .pipe(this.takeUntilDestroy())
      .subscribe(myOrganisation => {
        const hasSchoolAdmin = myOrganisation.careers.some(career => career.roleName === 'schooladmin');
        const isLicenseTabVisible = environment.manageLicenseTab || !hasSchoolAdmin;
        this.menuItems = this.menuItems.filter(item => item.id !== 'licenses-btn' || isLicenseTabVisible);
      });
  }

  private getCurrentOrganisation(): Observable<MyOrganisation> {
    const organisationContextService = this.injector.get(LibOrganisationContextService);
    return organisationContextService.getCurrent()
      .pipe(
        this.takeUntilDestroy(),
        shareReplay()
      )
  }

  toggle(isOpen?: boolean): void {
    this.sidenav?.toggle(isOpen);
  }

  onAfterCreate(): void {
    setTimeout(() => {
      if (this.mobileQuery.matches) {
        this.sidenav?.toggle(this.defaultToggleStatus.mobile);
      } else {
        this.sidenav?.toggle(this.defaultToggleStatus.desktop);
      }
      this.initialized = true;
    }, 200);
  }
}
